.dashboard-content {
  padding-top: 70px;
}

.dashboard-layout {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* Cards Section */
.cards-section {
  flex: 4;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* Extra Space Section */
.extra-space {
  flex: 0.5;
  padding-left: 15px;
}

/* Card Styles */
.custom-card {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 5px;
}

.card-image-wrapper {
  max-height: 100px;
  overflow: hidden;
}

.card-image {
  width: 100%;
  height: auto;
}

.card-title {
  font-size: 0.85rem;
}

.discount-style, .original-style, .offer-style {
  font-size: 0.75rem;
}

.brand-img {
  max-height: 30px;
  max-width: 60px;
}

/* Responsive Styles */

/* Mobile View (max-width: 576px) - 6 cards per row */
@media (max-width: 576px) {
  .cards-section .col {
    flex: 0 0 16%; 
    /* 6 cards per row (100% / 6 ≈ 16%) */
  }
.cards-section{
  gap: 31px;
}
  .extra-space {
    display: none; /* Hide extra content on small screens */
  }

  .custom-card {
    padding: 5px;
  }

  .card-image-wrapper {
    max-height: 80px; /* Further reduce image height */
  }

  .card-title {
    font-size: 0.7rem; /* Smaller title */
  }
  .date-style{
    font-size: 0.8rem; /* Smaller title */

  }

  .discount-style, .original-style, .offer-style {
    font-size: 0.6rem; /* Smaller price font */
  }

  .brand-img {
    max-height: 20px;
    max-width: 50px;
  }

  .card-footer Button {
    width: 100px; /* Smaller button */
    height: 20px;
    font-size: 8px; /* Smaller text in button */
    padding: 5px
  }
}

/* Tablet View (max-width: 1024px) */
@media (max-width: 1024px) {
  .cards-section {
    flex: 2; /* Cards take more space */
  }

  .extra-space {
    flex: 1; /* Extra space stays smaller */
  }

  .cards-section .col {
    flex: 0 0 33.33%; /* 3 cards in tablet view */
  }
}

/* Tablet View (max-width: 768px) */
@media (max-width: 768px) {
  .dashboard-layout {
    flex-direction: column; /* Stack sections vertically */
  }

  .extra-space {
    margin-top: 20px; /* Add margin when stacked */
  }

  .cards-section .col {
    flex: 0 0 50%; /* 2 cards per row in mobile */
  }

  .card-title {
    font-size: 0.9rem; /* Adjust font size for mobile */
  }

  .discount-style, .original-style, .offer-style {
    font-size: 0.8rem; /* Adjust price font size for mobile */
  }
}

/* Desktop View (min-width: 769px) */
@media (min-width: 769px) {
  .cards-section .col {
    flex: 0 0 25%; /* 4 cards per row in desktop */
  }
}
