.navbar-Links {
    margin-left: 50px;
    gap: 2rem;
}

.navColor{
    background-color: #fbd45d;
}

.navLinkText{
    font-weight: 600;
}