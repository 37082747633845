/* Dashboard.css */

/* Set layout for card container */
.cards-section {
  display: flex;
  flex-wrap: wrap;
  gap: 15px; /* Gap between cards */
}

/* Styling for individual cards */
.custom-card {
  width: 200px; /* Increased card width */
  height: 400px; /* Increased card height */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Uniform image size */
.card-image-wrapper {
  /* height: 200px;  */
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 33px;
}

.card-image {
  object-fit: cover; /* Ensures image scales properly */
  width: 100%; /* Ensures the image covers the full width */
  height: 100%; /* Ensures the image covers the full height */
}

/* Card title */
.card-title {
  font-size: 12px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}

/* Footer with brand image and button */
.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px;
}

.brand-img {
  width: 50px;
  height: 20px;
}

.price-section{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
}

.discount-info{
  /* flex-direction:'row'; */
  display:flex;
  justify-content:'space-between';
  align-items: center;
  gap: 10px;
}

.discount-style{
  font-size: 14px;
  font-weight: 600;
  color: #1F51FF;
}

.original-style{
  font-size: 12px;
  text-decoration: line-through;
}

.offer-style{
  font-weight: 600;
  font-size: 18px;
  color: #FF5733;
}

.date-style{
  font-size: 14px;
  text-align: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .custom-card {
    width: 180px; /* Smaller card size for tablet view */
    height: 350px;
  }
}

@media (max-width: 576px) {
  .custom-card {
    width: 155px; /* Even smaller size for mobile */
    height: 300px;
  }
  .cards-section{
    gap: 32px;
  }
  .card-footer{
    padding: 5px
  }
}
